import * as React from 'react';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import skinsStyle from './styles/skins.scss';

const ButtonLiftedShadowSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(({ wrapperProps, linkProps, elementType, label, onFocus, onBlur }, ref) => {
  return (
    <div {...wrapperProps} className={skinsStyle.ButtonLiftedShadow}>
      <div className={`${skinsStyle.left} ${skinsStyle.shd}`} />
      <div className={`${skinsStyle.right} ${skinsStyle.shd}`} />
      <SiteButtonContent
        linkProps={linkProps}
        elementType={elementType}
        className={skinsStyle.link}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      >
        <span className={skinsStyle.label}>{label}</span>
      </SiteButtonContent>
    </div>
  );
});

const ButtonLiftedShadow: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ButtonLiftedShadowSkin} ref={ref} />
);

export default React.forwardRef(ButtonLiftedShadow);
